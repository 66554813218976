import { cloneDeep, isEqual, isObject, transform } from 'lodash-es';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';

import type { Channel, Priority, Suggestion, TicketType } from '@eeedo/types';
import type { WithTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router-dom';
import type { SemanticICONS } from 'semantic-ui-react';

import LoaderComponent from '../../Loader/Loader';
import CaseContentEditor from '../CaseContentEditor';
import TopBarGeneralInfo from 'src/Components/Case/TopBar/TopBarGeneralInfo';
import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import Comments from 'src/Components/Comments/Comments';
import DraftsContainer from 'src/containers/DraftsContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { getUrlSearchParam } from 'src/Utilities/helper';

import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { ReplyTabIndex } from 'src/types/Drafts';
import type { Ticket } from 'src/types/Ticket';

import '../Case.css';

interface CaseProps extends RouteComponentProps, WithTranslation {
  history: any;
  location: any;
  match: any;
  task: Ticket;
  usersList: any;
  userData: any;
  user: any;
  ticketTypes: TicketType[];
  externalLinks: any[];
  ticketChannels: any;
  ticketPriorities: any;
  mobileMode: boolean;
  autosuggestions: Suggestion[];
  replyTabIndexList: ReplyTabIndex[];
  widgetOrder: string[];

  fireTicketSearch(filter: any, _id?: string, searchCriteria?: any[]): void;

  addEntityToCase(ticketId: string, body: object, subEntitiesToGet?: any): Promise<void>;

  removeEntityFromCase(ticketId: string, body: object): void;

  fetchContent(id: string): void;

  getEntityById(ticketId: string, ticketType: string, entityId: string, entityType: string): void;

  updateTicketCaseDetails(ticketId: string, updateKey: string, updateValue: any, group: string): void;

  updateEntityDetails(ticketId: string, taskType: string, updateArgs: UpdateEntityDetail): void;

  updateTicket(ticket: Ticket): void;

  addDelegateGroupToContent(ticket: any, usrGroup: number[]): void;

  uploadFile(ticketId: string, file: any): void;

  deprecateAttachment(ticketId: string, attachmentId: string): void;

  unDeprecateAttachment(ticketId: string, attachmentId: string): void;

  editAttachment(ticketId: string, attachmentId: string, body: any): void;

  removeDelegateFromContent(ticket: any, usr: string): void;

  updateMetadata(id: number | string, metadata: object): void;

  addDelegateToContent(ticket: any, usr: string): void;

  onSearchPreviousTickets(entityId: string, entityType: string): void;

  openTab(id: string): void;

  changeEntityFields(data: any, id: any, entityDisplayName: string): void;
}

interface CaseState {
  entityFields: any[];
  handleInfo: any[];
  channelTypes: {
    text: string;
    value: number;
    icon: SemanticICONS;
  }[];
  ticketPriorityLevels: {
    text: string;
    value: number;
    icon: SemanticICONS;
    color: string;
  }[];
}

// TODO info pages merge this and case.tsx
class InfopageCase extends React.Component<CaseProps, CaseState> {
  entityInfo: any;
  additionalCustomerFieldSets: any;
  entityInfoHeader: any;
  entitySearchButton: string;
  fieldMap: Map<string, any>;
  ticketTypes: Map<string, any>;
  detailGroupMap: Map<string, any>;
  ticketTypeNames: string[];
  fieldSetNames: string[];
  senderEmails: any[];
  private taskStatusOptions: {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[] = [
    {
      text: this.props.t('INFOPAGE_STATUS_DRAFT'),
      icon: 'firstdraft',
      value: 'draft'
    },
    {
      text: this.props.t('INFOPAGE_STATUS_INREVIEW'),
      icon: 'eye',
      value: 'inReview'
    },
    {
      text: this.props.t('INFOPAGE_STATUS_WAITING'),
      icon: 'wait',
      value: 'waitingToBePublished'
    },
    {
      text: this.props.t('INFOPAGE_STATUS_PUBLISHED'),
      icon: 'check',
      value: 'published'
    },
    {
      text: this.props.t('INFOPAGE_STATUS_WASTE'),
      icon: 'trash',
      value: 'waste'
    }
  ];

  constructor(props: CaseProps) {
    super(props);

    this.entityInfo = [];
    this.additionalCustomerFieldSets = [];
    this.entityInfoHeader = props.t('CASE_TITLE_CUSTOMER');
    this.entitySearchButton = '';
    this.ticketTypes = new Map();
    this.fieldSetNames = [];
    this.ticketTypeNames = [];
    this.fieldMap = new Map();
    this.detailGroupMap = new Map();
    this.senderEmails = [];

    this.state = {
      entityFields: [],
      handleInfo: [],
      channelTypes: this.props.ticketChannels
        .filter((channel: Channel) => {
          return channel.active;
        })
        .map((channelType: Channel) => ({
          text: channelType.channel,
          value: channelType.id,
          icon: channelType.icon
        })),
      ticketPriorityLevels: this.props.ticketPriorities.map((ticketPriority: Priority) => ({
        text: props.t(ticketPriority.text),
        value: ticketPriority.value,
        icon: ticketPriority.icon,
        color: ticketPriority.color
      }))
    };
  }

  shouldComponentUpdate(nextProps: any) {
    const diff = this.difference(nextProps, this.props);
    const taskDiff = nextProps.task && this.props.task ? this.difference(nextProps.task, this.props.task) : {};

    if (Object.keys(diff).length === 0 && Object.keys(taskDiff).length === 0) {
      return false;
    }
    return true;
  }

  componentWillReceiveProps(nextProps: CaseProps) {
    if (nextProps.task) {
      if (nextProps.task.entityFields) {
        this.entityInfo = nextProps.task.entityFields;
        this.entityInfoHeader = nextProps.task.entityFieldDisplayName;
      }

      if (nextProps.ticketTypes !== undefined && nextProps.ticketTypes.length > 0 && !nextProps.task.entityFields) {
        this.fieldSetNames = [];
        this.ticketTypeNames = [];
        this.fieldMap = new Map();
        this.ticketTypes = new Map();
        this.detailGroupMap = new Map();
        this.senderEmails = [];

        nextProps.ticketTypes.forEach((ticketType) => {
          this.ticketTypeNames.push(ticketType.name);
          this.ticketTypes.set(ticketType.name, ticketType.fieldSets);

          const senderEmail = cloneDeep(ticketType);
          if (senderEmail.senderemail) {
            senderEmail.senderemail.type = senderEmail.name;

            if (senderEmail.name === nextProps.task.taskType) {
              senderEmail.senderemail.default = true;
            }
            this.senderEmails.push(senderEmail.senderemail);
          }
        });

        const ticketType = [...nextProps.ticketTypes].find((type) => type.name === nextProps.task.taskType);

        const fieldSets = this.ticketTypes.get(nextProps.task.taskType);
        if (fieldSets && fieldSets.length > 0) {
          fieldSets.forEach((fieldSet: any) => {
            if (fieldSet.id === 'caseInfo') {
              this.entitySearchButton = fieldSet.customSearch;
            }
            if (fieldSet.id === 'customerInfo') {
              this.entityInfo = fieldSet[fieldSet.id];
              this.entityInfoHeader = ticketType?.defaultFieldSet ? ticketType.defaultFieldSet : fieldSet.displayName;
              this.additionalCustomerFieldSets = fieldSet.additionalFieldSets;
            } else if (fieldSet.id === 'status') {
              this.setState({
                handleInfo: fieldSet[fieldSet.id]
              });
            } else {
              this.fieldSetNames.push(fieldSet.displayName);
              this.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
              this.fieldMap.set(fieldSet.displayName, fieldSet[fieldSet.id]);
            }
          });
        }
      }
    }
  }

  componentDidMount() {
    this.props.openTab(this.props.match.params.id);
    this.props.fetchContent(this.props.match.params.id);
  }

  private difference = (x: any, y: any) => {
    function changes(object: any, base: any) {
      return transform(object, function (result: any, value: any, key: any) {
        if (!isEqual(value, base[key])) {
          result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }

    return changes(x, y);
  };

  render() {
    const { t } = this.props;
    if (this.props.task === undefined) {
      return <LoaderComponent />;
    }
    const isNavigationHidden = !!getUrlSearchParam('hideNavigation');

    return (
      <ErrorBoundary>
        <div
          id={this.props.mobileMode ? 'mobileElement topelement' : 'topelement'}
          className={isNavigationHidden ? 'hiddenNavigation' : ''}
          style={{ padding: '24px' }}
        >
          <div
            className={this.props.mobileMode ? 'mobileElement detailViewScrollContainer' : 'detailViewScrollContainer'}
          >
            <div className={this.props.mobileMode ? 'case-left-side-mobile' : 'case-left-side'}>
              <TopBarGeneralInfo contentType="infopage" />
              {this.props.mobileMode && (
                <TopBarStatusButtons contentType="infopage" statusOptions={this.taskStatusOptions} />
              )}

              {this.props.userData.permissions.includes('updateContent') && <CaseContentEditor />}

              <Comments ticket={this.props.task} senderEmails={this.senderEmails} mobileMode={this.props.mobileMode} />

              {this.props.userData.permissions.includes('updateContent') && (
                <>
                  <Header as="h3">
                    <Icon name="talk" />
                    {t('CASE_ANSWER')}
                  </Header>

                  <ErrorBoundary>
                    <DraftsContainer />
                  </ErrorBoundary>
                </>
              )}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default withRouter(withTranslation()(InfopageCase));
